import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ClubLogo from '../../images/Collegelogo.png';

function Header() {
    const [IsLoading, SetIsLoading] = useState(false);
    const [getdata, setdata] = useState([]);
    const [IsLogin, SetIsLogin] = useState(false);
    const verifydata = async () => {
        try {
            SetIsLoading(true)
            const res = await fetch('/api/v1/student/profile/data', {
                method: 'GET',
                credentials: 'include'
            })

            if (res.status === 200) {
                const data = await res.json();
                if (data.data) {
                    if (data.data.participant_Profile_Avtar) {
                        const uint8Array = new Uint8Array(data.data.participant_Profile_Avtar.data.data);
                        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                        const dataUrl = URL.createObjectURL(blob);
                        data.data.participant_Profile_Avtar = dataUrl;
                    }
                }
                setdata(data.data);
                SetIsLoading(false)
                SetIsLogin(true);
            } else {
                SetIsLoading(false)
                SetIsLogin(false);
            }
        } catch (error) {
            SetIsLogin(false);
            SetIsLoading(false)
        }
    }
    const navigate = useNavigate();
    useEffect(() => {
        verifydata();
    }, [])
    const Headerslidehandel = () => {
        const Header = document.querySelector("#Header");
        if (Header !== null) {
            if (Header.classList.contains("max-[1280px]:left-[-100%]")) {
                Header.classList.remove("max-[1280px]:left-[-100%]");
            } else {
                Header.classList.add("max-[1280px]:left-[-100%]");
            }
        }
    };




    const OnClickprofileMenu = () => {
        const profileMenu = document.querySelector('#profileMenu');
        if (profileMenu) {
            if (profileMenu.classList.contains('xl-[1280px]:hidden')) {
                profileMenu.classList.remove('xl-[1280px]:hidden');
            } else {
                profileMenu.classList.add('xl-[1280px]:hidden');
            }

        }

    }

    // const settingParticipantDetail = () => {
    //     navigate('/student/profile/settings/');
    //     OnClickprofileMenu();
    // }
    // const LogOutParticipant = async () => {
    //     const logOutverification = window.confirm("Are you sure to logout ");
    //     if (logOutverification) {
    //         try {
    //             SetIsLoading(true)
    //             const res = await fetch('/api/v1/student/profile/logout', {
    //                 method: 'GET',
    //                 credentials: 'include'
    //             })
    //             if (res.status === 200) {
    //                 setdata([]);
    //                 SetIsLoading(false)
    //                 SetIsLogin(false);
    //             } else {
    //                 SetIsLoading(false)
    //                 SetIsLogin(false);
    //             }
    //         } catch (error) {
    //             SetIsLogin(false);
    //             SetIsLoading(false)
    //         }
    //         OnClickprofileMenu();
    //     } else {
    //         OnClickprofileMenu();
    //     }
    // }

    return (
        <>
            <div className=" w-[100%] h-[70px] z-40 sticky top-0 bg-[#161d2c] border-b-[1px] border-[#2b3148] flex justify-center items-center">
                <div className={`w-[24%] h-[auto] flex justify-center items-center max-[1280px]:w-[90%] ${IsLogin ? "max-[700px]:w-[80%]" : ""} max-[1280px]:justify-start max-[1280px]:ml-4`}>
                    <NavLink to="/" className="flex justify-center items-center gap-2">
                        <div className=" w-[60px] h-[60px] max-[480px]:w-[50px]  max-[480px]:h-[50px]  flex justify-center items-center pb-[5px] pt-[5px] ">
                            <img
                                src={ClubLogo}
                                className="w-[60px] h-[60px]  max-[480px]:w-[50px]  max-[480px]:h-[50px] "
                                alt="Loading.."
                            />
                        </div>
                        <h1 className=" text-[30px] font-[700] text-[#fff]  uppercase max-[800px]:text-[25px] max-[480px]:text-[20px]">
                            GecSiwan
                        </h1>
                    </NavLink>
                </div>
                {
                    IsLogin ? (
                        <div className="hidden  select-none w-[70px] pr-[10px] h-[100%] max-[1280px]:flex justify-end items-center  ">
                            <div onClick={Headerslidehandel} className={`  w-[45px] h-[45px] cursor-pointer    rounded-[50%] border-[2px] border-[#2c4056] flex justify-center items-center`}>
                                <img className="w-[45px] h-[45px] rounded-[50%] " src={Object(getdata).participant_Profile_Avtar ? Object(getdata).participant_Profile_Avtar : Object(getdata).participant_Avtar} alt="Avtar" />
                                <span
                                    className=" material-symbols-outlined text-[#ffffff] font-[800] rounded-[10px] absolute max-[480px]:text-[20px] top-[37px] text-[25px] cursor-pointer"
                                >
                                    menu
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="hidden  select-none w-[10%]  pr-[10px] h-[100%] max-[1280px]:flex justify-end items-center  ">
                            <span
                                onClick={Headerslidehandel}
                                className=" material-symbols-outlined text-[#ffffff] font-[800] rounded-[10px]   max-[480px]:text-[30px]   text-[35px] cursor-pointer"
                            >
                                menu
                            </span>
                        </div>

                    )
                }
                <nav
                    id="Header"
                    style={{ transition: 'all 1s' }}
                    className="w-[76%] h-[100%] flex transition ease-in-out delay-200 justify-center max-[1280px]:h-[100%] max-[1280px]:w-[300px] max-[480px]:w-[250px]     max-[1280px]:bg-[#161d2c]     max-[1280px]:fixed top-[70px] left-0  max-[1280px]:left-[-100%] items-center max-[480px]:justify-end overflow-auto max-[1280px]:pl-[20px]   "
                >
                    <ul className="w-[100%] h-[100%] max-[480px]:ml-[20px]   flex justify-end items-center gap-12 max-[1400px]:gap-6 max-[1280px]:gap-4  max-[1280px]:overflow-y-auto  max-[1280px]:w-[250px]  max-[1280px]:pt-[20px] max-[480px]:w-[210px]  max-[1280px]:block pr-[50px] ">

                        <NavLink
                            onClick={Headerslidehandel}
                            to="/"
                            className="text-[#fff] text-center text-[14px] uppercase transition-all max-[1280px]:text-start ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">Home</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/matlab/national/workshop/registration"
                            className="text-[#fff] text-center text-[14px] uppercase transition-all max-[1280px]:text-start ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">Workshop</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/certificate/"
                            className="text-[#fff] text-center text-[14px] transition-all  max-[1280px]:text-start ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">CERTIFICATE</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/gallery/"
                            className="text-[#fff] text-[14px]  max-[1280px]:text-start    text-center transition-all ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">GALLERY</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/community"
                            className="text-[#fff] text-center max-[1280px]:text-start text-[14px] transition-all ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">COMMUNITY</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/technical-club/contacts"
                            className="text-[#fff] text-center max-[1280px]:text-start text-[14px] transition-all ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6"> CONTACT US</li>
                        </NavLink>
                        <NavLink
                            onClick={Headerslidehandel}
                            to="/government-engineering-college-siwan/technical-club/contacts"
                            className="text-[#fff] text-center max-[1280px]:text-start text-[14px] transition-all ease-in-out delay-200 font-[600]  hover:text-[red]"
                        >
                            <li className="max-[1280px]:mb-6">Login</li>
                        </NavLink>
                    </ul>
                </nav>
            </div>
        </>
    );
}

export default Header;
