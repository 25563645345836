import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home.jsx";
import Header from "./components/pages/Header.jsx";
import WorkshopRegistration from "./components/pages/WorkshopRegistration.jsx";
import Certificate from "./components/pages/Certificate.jsx";
import Community from "./components/pages/Community.jsx";
import Gallery from "./components/pages/Gallery.jsx";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/government-engineering-college-siwan/matlab/national/workshop/registration/" element={<WorkshopRegistration />} />
        <Route path="/government-engineering-college-siwan/certificate/" element={<Certificate />} />
        <Route path="/government-engineering-college-siwan/community" element={<Community />} />
        <Route path="/government-engineering-college-siwan/gallery" element={<Gallery />} />
      </Routes>
    </>
  );
}

export default App;
