import React from 'react'

function Certificate() {
    return (
        <>
            <div className='w-full h-[80vh]'>
                <div className='w-[100%] h-[100%] flex justify-center items-center p-[26px]  space-y-10'>
                    <h1 className=' capitalize text-[40px] font-[700] max-[1050px]:text-[30px] max-[800px]:text-[25px] text-center animate-pulse text-[#ffdd00] max-[480px]:text-[15px] max-[300px]:text-[12px]  '>This Time Cerificate not available </h1>
                </div>
            </div>
        </>
    )
}

export default Certificate
