import React from 'react'

function WorkshopRegistration() {
  return (
    <>
     <div className='w-[100%] h-[90vh] p-[20px] flex justify-center items-center max-[600px]:p-[0px] max-[600px]:pt-[20px]'>
     <iframe src="https://docs.google.com/forms/d/1mac4Dm1_366kS7HFSZ3G5Zi-bkUtYRylyuVIGYFKfGk/viewform?embedded=true" id='workshop-form' className='bg-[#3c404b] rounded-[10px] max-[1000px]:w-[600px] max-[600px]:w-[100%] pt-[20px]' height="100%" width="800" >Loading…</iframe>
     </div>
    </>
  )
}

export default WorkshopRegistration
