import React from 'react'
import NationalWorkshop from '../../images/NationalWorkshop.jpg'
import { NavLink } from 'react-router-dom'
function Home() {
    return (
        <>
            <div className='w-[100%] h-[80px] flex justify-center items-center xm:p-[20px] xm:h-[40px]'>
                <h1 className=' text-[40px] select-none font-[700] max-[1050px]:text-[30px] max-[800px]:text-[25px] text-center animate-pulse text-[#41ff34] max-[480px]:text-[15px] max-[300px]:text-[12px]  capitalize '>National Workshop At GEC Siwan</h1>
            </div>
            <div className='w-[100%] h-[100%] p-[20px] max-[480px]:p-0 flex justify-center items-center'>
                <img src={NationalWorkshop} alt="NationalWorkshop" className='rounded-[5px]' />
            </div>
            <div className='w-[100%] h-[100%] p-[20px] flex justify-center items-center flex-col gap-2'>
                <h1 className=' select-none flex gap-2 text-[20px] font-[600px] justify-center items-center max-[480px]:text-[16px]'>Registration Start:
                    <span className="text-[#30ff4b] text-[18px]  max-[480px]:text-[14px] flex justify-center items-center">
                        25/04/2024
                    </span>
                </h1>
                <h1 className=' select-none flex gap-2 text-[20px] font-[600px] justify-center items-center max-[480px]:text-[16px] mr-3'>Registration End:
                    <span className=" text-[#ff3c80] text-[18px]  max-[480px]:text-[14px] flex justify-center items-center">
                        05/05/2024
                    </span>
                </h1>
                <h1 className=' select-none flex gap-2 text-[20px] font-[600px] justify-center items-center max-[480px]:text-[16px]'>Registration Link:
                    <NavLink to='/government-engineering-college-siwan/matlab/national/workshop/registration' style={{ transition: 'all 1s' }} className="hover:text-[#ffe062] text-[#30ff4b] text-[18px]  max-[480px]:text-[14px] flex justify-center items-center">
                        Register Now
                    </NavLink>
                </h1>
            </div>

        </>
    )
}

export default Home
